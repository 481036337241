import React from "react";
import Layout from "../components/layout-contents";
import styles from "../styles/review.module.css";
import Container from "../components/container";
import { graphql } from "gatsby";

const User = (props) => (
  <div className={styles.topUser}>
    <div className={styles.descUser}>
      <img
        src="/images/about/christian/g6920-8-9.png"
        className={styles.logoUser}
        alt=""
      />
      <p className={styles.excerpt}>
      From the woods of Södertälje, this friendly, furry wolf executes sick drawings of gore, sex and general psycho violent shit.<br/>
      NOT FOR SENSITIVE UNICORNS!
      </p>
      <div className={styles.linkUser} >
      <h3 style={{ marginLeft: "0px" , marginBottom: "0px"}} ><a href="mailto:christian.lofwall@tele2.se">CONTACT</a></h3>
        <h3 style={{ marginLeft: "calc(1rem + 4.5vmin)" ,marginBottom: "0px"}}><a href="https://codafanzine.bandcamp.com/merch/christian-l-fwalls-snuff-bunnies-fanzine" target="_blank" rel="noreferrer">SHOP</a></h3>
</div>
    </div>
    <a href={props.file} target="_blank" rel="noreferrer" style={{ display: "contents" }}>
      <img
        src="/images/about/christian/artopen_christianlofwall_foto.png"
        className={styles.imageUser}
        alt=""
      />
    </a>
  </div>
);

const Post = (props) => (
  <div>
 <img src={props.image}  alt="" />
    </div>
);

/*
  <h1>About CSS Modules</h1>
    <p>CSS Modules are cool</p>
*/
export default ({ data }) => (
  <Layout>
    <Container>
      <User></User>
      {data.allChristianJson.edges.map(({ node }) => (
        <Post
          image={node.image}
        />
      ))}
    </Container>
  </Layout>
);

export const query = graphql`
  query {
    allChristianJson {
      edges {
        node {
          image
        }
      }
    }
  }
`;
